import { defineMessages } from 'react-intl';

const genericMessages = defineMessages({
    idl: {
        id: 'idl',
        defaultMessage: 'IDL',
    },
    defaultError: {
        id: 'error.default',
        defaultMessage:
            'Nous sommes désolés, une erreur inattendue est survenue. Veuillez réessayer plus tard ou contacter le support.',
        description: 'Default error message',
    },
    defaultErrorWithStatus: {
        id: 'error.default_with_status',
        defaultMessage:
            'Nous sommes désolés, une erreur inattendue est survenue. Veuillez réessayer plus tard ou contacter le support. ({status})',
        description: 'Default error message',
    },
    forbiddenError: {
        id: 'error.forbidden',
        defaultMessage: "Nous sommes désolés, vous n'êtes pas autorisé à accéder à cette ressource.",
        description: 'Forbidden error message',
    },
    unauthorizedError: {
        id: 'error.unauthorized',
        defaultMessage: 'Nous sommes désolés, vous devez être authentifié pour accéder à cette ressource.',
        description: 'Unauthorized error message',
    },
    invalidDataSentError: {
        id: 'error.invalid_data_sent',
        defaultMessage: 'Nous sommes désolés, les informations envoyées ne sont pas valides.',
        description: 'Invalid data sent error message',
    },
    notFoundError: {
        id: 'error.not_found',
        defaultMessage: "Nous sommes désolés, la ressource que vous cherchez n'existe pas.",
        description: 'Not found error message',
    },
    invalidFileFormatError: {
        id: 'error.invalid_file_format',
        defaultMessage: 'Le fichier ne correspond pas au format attendu',
        description: 'Invalid file format error',
    },
    defaultExportError: {
        id: 'error.export',
        defaultMessage: "Echec de l'export. Veuillez réessayer plus tard ou contacter le support.",
        description: 'Default export error message',
    },
    yes: {
        id: 'yes',
        defaultMessage: 'Oui',
    },
    no: {
        id: 'no',
        defaultMessage: 'Non',
    },
    add: {
        id: 'add',
        defaultMessage: 'Ajouter',
    },
    newPasswordError: {
        id: 'new_password_error',
        defaultMessage: 'Le nouveau mot de passe doit être différent de l’ancien',
        description: 'NewPassword error message',
    },
    oldPasswordError: {
        id: 'old_password_error',
        defaultMessage: 'Ancien mot de passe incorrect',
        description: 'Password error message',
    },
    enterOldPasswordError: {
        id: 'enter_old_password_error',
        defaultMessage: 'Ancien mot de passe obligatoire pour le changement de mot de passe',
        description: 'Password error message',
    },
    exportData: {
        id: 'export_data',
        defaultMessage: 'Exporter les données',
        description: 'Button label to export data',
    },
    valuesLists: {
        id: 'values_lists',
        defaultMessage: 'Listes de valeurs',
        description: 'Values lists',
    },
    brand: {
        id: 'brand',
        defaultMessage: `{count, plural, =0 {Marque} one {Marque} other {Marques}}`,
        description: 'Brand',
    },
    model: {
        id: 'model',
        defaultMessage: `{count, plural, =0 {Modèle} one {Modèle} other {Modèles}}`,
        description: 'Model',
    },
    type: {
        id: 'type',
        defaultMessage: `{count, plural, =0 {Type} one {Type} other {Types}}`,
        description: 'Type',
    },
    warehouse: {
        id: 'warehouse',
        defaultMessage: `{count, plural, =0 {Entrepôt} one {Entrepôt} other {Entrepôts}}`,
        description: 'Warehouse',
    },
    place: {
        id: 'place',
        defaultMessage: `{count, plural, =0 {Entrepôt / Magasin} one {Entrepôt / Magasin} other {Entrepôts / Magasins}}`,
        description: 'place',
    },
    name: {
        id: 'name',
        defaultMessage: 'Nom',
        description: 'Name',
    },
    group: {
        id: 'groups',
        defaultMessage: '{count, plural, =0 {Groupe} one {Groupe} other {Groupes}}',
        description: 'Group',
    },
    address: {
        id: 'address',
        defaultMessage: '{count, plural, =0 {Adresse} one {Adresse} other {Adresses}}',
        description: 'Address',
    },
    addressPlaceholder: {
        id: 'address.placeholder',
        defaultMessage: 'Saisir une adresse',
        description: 'Address placeholder',
    },
    postalAddress: {
        id: 'postal_address',
        defaultMessage: 'Adresse postale',
        description: 'Address (postal)',
    },
    editAddress: {
        id: 'edit_address',
        defaultMessage: 'Modifier l’adresse',
        description: 'Edit address',
    },
    editIdentity: {
        id: 'edit_identity',
        defaultMessage: "Modifier l'identité",
        description: 'Edit identity',
    },
    zipCode: {
        id: 'zip_code',
        defaultMessage: 'Code postal',
        description: 'Zip code',
    },
    zipCodePlaceholder: {
        id: 'zip_code.placeholder',
        defaultMessage: 'Saisir un code postal',
        description: 'Zip code placeholder',
    },
    city: {
        id: 'city',
        defaultMessage: 'Ville',
        description: 'City',
    },
    cityPlaceholder: {
        id: 'city.placeholder',
        defaultMessage: 'Saisir une ville',
        description: 'City placeholder',
    },
    country: {
        id: 'country',
        defaultMessage: 'Pays',
        description: 'Country',
    },
    lastName: {
        id: 'lastName',
        defaultMessage: 'Nom',
        description: 'Last name',
    },
    lastNamePlaceholder: {
        id: 'lastName.placeholder',
        defaultMessage: 'Saisir un nom',
        description: 'Last name placeholder',
    },
    firstName: {
        id: 'firstName',
        defaultMessage: 'Prénom',
        description: 'First name',
    },
    firstNamePlaceholder: {
        id: 'firstName.placeholder',
        defaultMessage: 'Saisir un prénom',
        description: 'First name placeholder',
    },
    email: {
        id: 'email',
        defaultMessage: 'E-mail',
        description: 'Email',
    },
    emailPlaceholder: {
        id: 'email.placeholder',
        defaultMessage: 'Saisir un E-mail',
        description: 'Email placeholder',
    },
    emailSendSuccess: {
        id: 'email.send.success',
        defaultMessage: 'Email envoyé avec succès',
    },
    phone: {
        id: 'phone',
        defaultMessage: 'Téléphone',
        description: 'Phone',
    },
    phonePlaceholder: {
        id: 'phone.placeholder',
        defaultMessage: 'Saisir un numéro de téléphone',
        description: 'Phone placeholder',
    },
    jobTitle: {
        id: 'job_title',
        defaultMessage: 'Fonction',
        description: 'Job title',
    },
    jobTitlePlaceholder: {
        id: 'job_title.placeholder',
        defaultMessage: 'Saisir une fonction',
        description: 'Job title placeholder',
    },
    actions: {
        id: 'actions',
        defaultMessage: 'Actions',
        description: 'Actions',
    },
    contact: {
        id: 'contact',
        defaultMessage: '{count, plural, =0 {Contact} one {Contact} other {Contacts}}',
        description: 'Contact',
    },
    contactEditButton: {
        id: 'contact_edit_button',
        defaultMessage: 'Modifier les contacts',
        description: 'Contact edit',
    },
    primaryContact: {
        id: 'primary_contact',
        defaultMessage: 'Contact principal',
        description: 'Primary contact',
    },
    administrativeContact: {
        id: 'administrative_contact',
        defaultMessage: 'Contact administratif',
        description: 'Administrative contact',
    },
    contract: {
        id: 'contract',
        defaultMessage: 'Contrat',
        description: 'Contract',
    },
    contractType: {
        id: 'contract_type',
        defaultMessage: 'Type de contrat',
        description: 'Contract type',
    },
    editTypeButton: {
        id: 'edit_type_button',
        defaultMessage: 'Modifier le type',
        description: 'Edit type',
    },
    editing: {
        id: 'editing',
        defaultMessage: 'Modification',
        description: 'Editing',
    },
    publicHoliday: {
        id: 'public_holiday',
        defaultMessage: 'Férié',
        description: 'Public holiday',
    },
    week: {
        id: 'week',
        defaultMessage: 'Semaine',
        description: 'Week',
    },
    month: {
        id: 'month',
        defaultMessage: 'Mois',
        description: 'Month',
    },
    monday: {
        id: 'monday',
        defaultMessage: 'Lundi',
        description: 'Day of week',
    },
    tuesday: {
        id: 'tuesday',
        defaultMessage: 'Mardi',
        description: 'Day of week',
    },
    wednesday: {
        id: 'wednesday',
        defaultMessage: 'Mercredi',
        description: 'Day of week',
    },
    thursday: {
        id: 'thursday',
        defaultMessage: 'Jeudi',
        description: 'Day of week',
    },
    friday: {
        id: 'friday',
        defaultMessage: 'Vendredi',
        description: 'Day of week',
    },
    saturday: {
        id: 'saturday',
        defaultMessage: 'Samedi',
        description: 'Day of week',
    },
    sunday: {
        id: 'sunday',
        defaultMessage: 'Dimanche',
        description: 'Day of week',
    },
    day: {
        id: 'day',
        defaultMessage: 'Jour',
        description: 'Day',
    },
    hours: {
        id: 'hours',
        defaultMessage: 'Horaires',
        description: 'Hours',
    },
    generalInformations: {
        id: 'general_informations',
        defaultMessage: 'Informations générales',
        description: 'General informations',
    },
    services: {
        id: 'services',
        defaultMessage: '{count, plural, =0 {Prestation} one {Prestation} other {Prestations}}',
        description: 'Services',
    },
    taskGroupServices: {
        id: 'task_group.services',
        defaultMessage: '{count, plural, =0 {Prestations Supp.} one {Prestation Supp} other {Prestations Supp}}',
        description: 'Task group additional services',
    },
    availabilities: {
        id: 'availabilities',
        defaultMessage: 'Disponibilités',
        description: 'Availabilities',
    },
    vehicleType: {
        id: 'vehicle_type',
        defaultMessage: 'Type de véhicule',
        description: 'Vehicle type',
    },
    minimumShort: {
        id: 'minimum.short',
        defaultMessage: 'Min.',
        description: 'Minimum short notation',
    },
    maximumShort: {
        id: 'maximum.short',
        defaultMessage: 'Max.',
        description: 'Maximum short notation',
    },
    price: {
        id: 'price',
        defaultMessage: 'Prix',
        description: 'Price',
    },
    pricePlaceholder: {
        id: 'price.placeholder',
        defaultMessage: 'Saisir un prix',
        description: 'Price placeholder',
    },
    workingHolidays: {
        id: 'working_holidays',
        defaultMessage: 'Jours fériés',
        description: 'Working holidays',
    },
    workingHolidaysEditButton: {
        id: 'working_holidays.edit_button',
        defaultMessage: 'Modifier les jours fériés',
        description: 'Working holidays',
    },
    workingHolidaysLabel: {
        id: 'working_holidays.label',
        defaultMessage: 'Sélectionnez ci-dessous les jours fériés qui seront travaillés :',
        description: 'Working holidays label',
    },
    workingHolidaysDetailLabel: {
        id: 'working_holidays.details.label',
        defaultMessage: 'Jours fériés travaillés',
        description: 'Working holidays label',
    },
    workingHolidaysEmpty: {
        id: 'working_holidays.empty',
        defaultMessage: 'Aucun jour férié travaillé',
        description: 'Working holidays empty text',
    },
    workDurationLimits: {
        id: 'work_duration_limits',
        defaultMessage: 'Limites du temps de travail',
        description: 'Working duration limits',
    },
    workDurationLimitsEditButton: {
        id: 'work_duration_limits.edit_button',
        defaultMessage: 'Modifier les limites',
        description: 'Edit limits',
    },
    openFromTo: {
        id: 'open_from_to',
        defaultMessage: 'Ouvert de {from} à {to}',
    },
    closed: {
        id: 'closed',
        defaultMessage: 'Fermé',
    },
    fullDay: {
        id: 'full_day',
        defaultMessage: 'Journée entière',
    },
    vehicles: {
        id: 'vehicles',
        defaultMessage: '{count, plural, =0 {Véhicule} one {Véhicule} other {Véhicules}}',
        description: 'Vehicle',
    },
    vehiclesGroup: {
        id: 'vehicles_group',
        defaultMessage: 'Flotte de véhicules',
        description: 'Vehicles group',
    },
    percentage: {
        id: 'percentage',
        defaultMessage: 'Pourcentage',
        description: 'Percentage',
    },
    groupLink: {
        id: 'group_link',
        defaultMessage: 'Rattachement',
        description: 'Group link',
    },
    groupLinkLong: {
        id: 'group_link.long',
        defaultMessage: 'Groupes de rattachement',
        description: 'Group link',
    },
    groupLinkEdit: {
        id: 'groupLink.edit_button',
        defaultMessage: 'Modifier le rattachement',
        description: 'Group link edit button',
    },
    cost: {
        id: 'cost',
        defaultMessage: 'Coût',
        description: 'Cost',
    },
    edit: {
        id: 'edit',
        defaultMessage: 'Modifier',
        description: 'Edit',
    },
    noData: {
        id: 'no_data',
        defaultMessage: 'Aucune donnée',
        description: 'No data',
    },
    primaryLabel: {
        id: 'primary_label',
        defaultMessage: 'Principal',
        description: 'Primary label',
    },
    administrativeLabel: {
        id: 'administrative_label',
        defaultMessage: 'Administratif',
        description: 'Administrative label',
    },
    pauses: {
        id: 'pauses',
        defaultMessage: 'Temps de pause',
        description: 'Pauses',
    },
    pausesLabel: {
        id: 'pauses_label',
        defaultMessage: 'Indiquer ci-dessous les temps de pauses par type de contrat, par jour et par tranche horaire',
        description: 'Indicate below the break times by type of contract, by day and by time slot',
    },
    pausesEditButton: {
        id: 'pauses_edit_button',
        defaultMessage: 'Modifier les temps',
        description: 'Edit pauses',
    },
    billing: {
        id: 'billing',
        defaultMessage: 'Facturation',
        description: 'Billing',
    },
    billingEditButton: {
        id: 'billing_edit_button',
        defaultMessage: 'Modifier la facturation',
        description: 'Edit billing',
    },
    amountSelect: {
        id: 'amount_select',
        defaultMessage: 'Saisir un montant',
        description: 'Amount select',
    },
    informations: {
        id: 'informations',
        defaultMessage: 'Informations',
        description: 'Informations',
    },
    historyAndAnalytics: {
        id: 'history_analytics',
        defaultMessage: 'Historique & analytiques',
        description: 'History and analytics',
    },
    taskGroup: {
        id: 'task_group',
        defaultMessage: '{count, plural, =0 {Tournée} one {Tournée} other {Tournées}}',
        description: 'Task group',
    },
    taskGroups: {
        id: 'taskGroups',
        defaultMessage: 'Tournées',
        description: 'Task groups',
    },
    dateAndTime: {
        id: 'date_and_time',
        defaultMessage: 'Date et heure',
        description: 'Date & time',
    },
    date: {
        id: 'date',
        defaultMessage: 'Date',
        description: 'Date',
    },
    dateRange: {
        id: 'date_range',
        defaultMessage: 'Du {fromDate} au {toDate}',
    },
    carbonEmission: {
        id: 'carbon_emission',
        defaultMessage: 'Emission CO<sub>2</sub>',
        description: 'Carbon emission',
    },
    operator: {
        id: 'operator',
        defaultMessage: 'Chauffeur',
        description: 'Operator',
    },
    past: {
        id: 'past',
        defaultMessage: 'Passé',
        description: 'Past',
    },
    forthcoming: {
        id: 'forthcoming',
        defaultMessage: 'À venir',
        description: 'Forthcoming',
    },
    status: {
        id: 'status',
        defaultMessage: 'Statut',
        description: 'Status',
    },
    today: {
        id: 'today',
        defaultMessage: "Aujourd'hui",
        description: 'Status',
    },
    percentageSelect: {
        id: 'percentage_select',
        defaultMessage: 'Saisir un pourcentage',
        description: 'Select a percentage',
    },
    tolerancePercentage: {
        id: 'tolerance_percentage',
        defaultMessage: 'Pourcentage de tolérance',
        description: 'Tolerance percentage',
    },
    numberSelect: {
        id: 'number_select',
        defaultMessage: 'Saisir un nombre',
        description: 'Select a number',
    },
    search: {
        id: 'search',
        defaultMessage: 'Rechercher',
        description: 'Search',
    },
    allStatuses: {
        id: 'all_statuses',
        defaultMessage: 'Tous les statuts',
        description: 'All statuses',
    },
    deliveries: {
        id: 'deliveries',
        defaultMessage: '{count, plural, one {Livraison} other {Livraisons}}',
        description: 'Deliveries',
    },
    reference: {
        id: 'reference',
        defaultMessage: 'Référence',
        description: 'Reference',
    },
    goToDetails: {
        id: 'go_to_details',
        defaultMessage: 'Voir la fiche',
        description: 'Call to action',
    },
    change: {
        id: 'change',
        defaultMessage: 'Changer',
        description: 'Call to action',
    },
    operators: {
        id: 'operators',
        defaultMessage: 'Chauffeurs',
        description: 'Operators',
    },
    inProgress: {
        id: 'in_progress',
        defaultMessage: 'En cours',
        description: 'In progress',
    },
    ended: {
        id: 'ended',
        defaultMessage: 'Terminé',
        description: 'Ended',
    },
    durationInHour: {
        id: 'duration_in_hour',
        defaultMessage:
            '{unitDisplay, select, long {{duration, plural, =1 {{duration} heure} other {{duration} heures}}} short {{duration} h} narrow {{duration}h} other {{duration}}}',
        description: 'Duration in hour',
    },
    durationInMinute: {
        id: 'duration_in_minute',
        defaultMessage:
            '{unitDisplay, select, long {{duration, plural, =1 {{duration} minute} other {{duration} minutes}}} short {{duration} min} narrow {{duration}m} other {{duration}}}',
        description: 'Duration in minute',
    },
    time: {
        id: 'time',
        defaultMessage: 'Temps',
        description: 'Time',
    },
    duration: {
        id: 'duration',
        defaultMessage: 'Durée',
        description: 'Duration',
    },
    period: {
        id: 'period',
        defaultMessage: 'Période',
        description: 'Period',
    },
    startDate: {
        id: 'start_date',
        defaultMessage: 'Date de début',
        description: 'Start date',
    },
    endDate: {
        id: 'end_date',
        defaultMessage: 'Date de fin',
        description: 'End date',
    },
    allDay: {
        id: 'all_day',
        defaultMessage: 'Journée',
    },
    halfDay: {
        id: 'half_day',
        defaultMessage: 'Demi-journée',
    },
    slot: {
        id: 'slot',
        defaultMessage: 'Moment',
    },
    customer: {
        id: 'customers',
        defaultMessage: '{count, plural, =0 {Client} one {Client} other {Clients}}',
        description: 'Customer',
    },
    export: {
        id: 'export',
        defaultMessage: 'Exporter',
        description: 'Export',
    },
    partner: {
        id: 'partner',
        defaultMessage: 'Partenaire',
        description: 'Partner (transport)',
    },
    total: {
        id: 'total',
        defaultMessage: 'Total',
        description: 'Total',
    },
    billingUnit: {
        id: 'billing_unit',
        defaultMessage: 'Unité de facturation',
        description: 'Billing unit',
    },
    start: {
        id: 'start',
        defaultMessage: 'Départ',
        description: 'Start time of a task group',
    },
    end: {
        id: 'end',
        defaultMessage: 'Retour',
        description: 'End time of a task group',
    },
    close: {
        id: 'close',
        defaultMessage: 'Fermer',
        description: 'Close (modal, etc...)',
    },
    cancel: {
        id: 'cancel',
        defaultMessage: 'Annuler',
        description: 'Annuler (modal, etc...)',
    },
    validate: {
        id: 'validate',
        defaultMessage: 'Valider',
        description: 'Valider (modal, etc...)',
    },
    file: {
        id: 'file',
        defaultMessage: 'Fichier',
        description: 'File',
    },
    select: {
        id: 'select',
        defaultMessage: 'Sélectionner',
        description: 'Select',
    },
    label: {
        id: 'label',
        defaultMessage: 'Libellé',
        description: 'Label',
    },
    transporters: {
        id: 'transporters',
        defaultMessage: '{count, plural, one {Partenaire de transport} other {Partenaires de transport}}',
        description: 'Transporters',
    },
    identity: {
        id: 'identity',
        defaultMessage: 'Identité',
        description: 'Identity',
    },
    editContact: {
        id: 'edit_contact',
        defaultMessage: 'Modifier le contact',
        description: 'Edit contact',
    },
    user: {
        id: 'user',
        defaultMessage: '{count, plural, one {Utilisateur} other {Utilisateurs}}',
    },
    role: {
        id: 'role',
        defaultMessage: 'Rôle',
    },
    unavailableFromTo: {
        id: 'unavailable',
        defaultMessage: 'Indisponible de {from} à {to}',
        description: 'Unavailable',
    },
    confirmation: {
        id: 'confirmation',
        defaultMessage: 'Confirmation',
        description: 'Confirmation',
    },
    toDefine: {
        id: 'toDefine',
        defaultMessage: 'À définir',
        description: 'To define',
    },
    modify: {
        id: 'modify',
        defaultMessage: 'Modifier',
    },
    wait: {
        id: 'wait',
        defaultMessage: 'Veuillez patientez',
    },
    save: {
        id: 'save',
        defaultMessage: 'Enregistrement en cours.',
    },
    mobilicError: {
        id: 'mobilic_error',
        defaultMessage: 'échec de la connexion à mobilic',
    },
    mobilicErrorDetail: {
        id: 'mobilic_error_detail',
        defaultMessage: 'Il n’a pas été possible de relier votre compte Mobilic à votre compte chauffeur Id Logistics.',
    },
    mobilicErrorDetailRequestDispacher: {
        id: 'mobilic_error_detail_request_dispacher',
        defaultMessage: 'Si le problème persiste, rapprochez-vous de votre dispatcher.',
    },
    mobilicSuccess: {
        id: 'mobilic_success',
        defaultMessage: 'Connexion à Mobilic établie',
    },
    mobilicSuccessDetail: {
        id: 'mobilic_success_detail',
        defaultMessage: 'Votre compte Mobilic est désormais connecté à votre compte chauffeur Id Logistics.',
    },
    closePage: {
        id: 'close_page',
        defaultMessage: 'Fermer cette page',
    },
    applyPeriod: {
        id: 'apply_period',
        defaultMessage: "Période d'application",
    },
    confirm: {
        id: 'confirm',
        defaultMessage: 'Confirmer',
        description: 'Confirmer',
    },
});

export default genericMessages;
